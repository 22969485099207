import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {}

  expandAll(event) {
    const returnedBenefits = $(event.currentTarget).parents('.returned-benefits');
    $('.tooltip').tooltip('hide');
    returnedBenefits.find('#expand-all').addClass('d-none');
    returnedBenefits.find('#collapse-all').removeClass('d-none');
    returnedBenefits.find('.control__expand').addClass('d-none');
    returnedBenefits.find('.control__collapse').removeClass('d-none');
    returnedBenefits.find('.settings-panel__content').removeClass('d-none');
  }
  
  collapseAll(event) {
    const returnedBenefits = $(event.currentTarget).parents('.returned-benefits');
    $('.tooltip').tooltip('hide');
    returnedBenefits.find('#expand-all').removeClass('d-none');
    returnedBenefits.find('#collapse-all').addClass('d-none');
    returnedBenefits.find('.control__expand').removeClass('d-none');
    returnedBenefits.find('.control__collapse').addClass('d-none');
    returnedBenefits.find('.settings-panel__content').addClass('d-none');
  }

  expand(event) {
    const settingsPanel = $(event.currentTarget).parents('.settings-panel');
    $('.tooltip').tooltip('hide');
    settingsPanel.find('.control__expand').addClass('d-none');
    settingsPanel.find('.control__collapse').removeClass('d-none');
    settingsPanel.find('.settings-panel__content').removeClass('d-none');
  }

  collapse(event) {
    const settingsPanel = $(event.currentTarget).parents('.settings-panel');
    $('.tooltip').tooltip('hide');
    settingsPanel.find('.control__expand').removeClass('d-none');
    settingsPanel.find('.control__collapse').addClass('d-none');
    settingsPanel.find('.settings-panel__content').addClass('d-none');
  }

  highlightToggle(event) {
    const row = $(event.currentTarget).parents('tr');
    row.toggleClass('selected')

    this.applyMutualExclusivity(row)
    this.updateFieldValues(row)
  }

  applyMutualExclusivity(row) {
    row = $(row)
    const networkType = row.find("[data-col='networkType']").text()
    const amountType = row.find("[data-col='amountType']").text()
    const levelCode = row.find("[data-col='levelCode']").text()
    let oppositeRow = null;
    const rows = $(this.element).find("tr")

    rows.each(function(ix) {
      const $this = $(this)
      if($this.find("td[data-col='amountType']").text() == amountType &&
         $this.find("td[data-col='levelCode']").text() == levelCode &&
         $this.find("td[data-col='networkType']").text() != networkType){
        oppositeRow = this
        if(row.hasClass("selected")) {
          $this.removeClass('selected')
          $this.find("input.boolean")[0].checked = false
        }
      }
    });
    return oppositeRow;
  }

  updateFieldValues(row) {
    row = $(row)
    const networkType = row.find("[data-col='networkType']").text()
    const amountType = row.find("[data-col='amountType']").text()
    const levelCode = row.find("[data-col='levelCode']").text()

    if(amountType == "Deductible" && levelCode == "IND") {
      if(row.hasClass("selected")) {
        window.coverage_annual_ind_deductible_total.value = row.find("[data-col='total']").text()
        window.coverage_ind_deductible_remaining.value = row.find("[data-col='remaining']").text()
      } else {
        window.coverage_annual_ind_deductible_total.value = ""
        window.coverage_ind_deductible_remaining.value = ""
      }
    } else if(amountType == "Out Of Pocket" && levelCode == "IND") {
      if(row.hasClass("selected")) {
        window.coverage_annual_ind_oop_total.value = row.find("[data-col='total']").text()
        window.coverage_ind_oop_remaining.value = row.find("[data-col='remaining']").text()
      } else {
        window.coverage_annual_ind_oop_total.value = ""
        window.coverage_ind_oop_remaining.value = ""
      }
    } else if(amountType == "Deductible" && levelCode == "FAM") {
      if(row.hasClass("selected")) {
        window.coverage_annual_family_deductible_total.value = row.find("[data-col='total']").text()
        window.coverage_family_deductible_remaining.value = row.find("[data-col='remaining']").text()
      } else {
        window.coverage_annual_family_deductible_total.value = ""
        window.coverage_family_deductible_remaining.value = ""
      }
    } else if(amountType == "Out Of Pocket" && levelCode == "FAM") {
      if(row.hasClass("selected")) {
        window.coverage_annual_family_oop_total.value = row.find("[data-col='total']").text()
        window.coverage_family_oop_remaining.value = row.find("[data-col='remaining']").text()
      } else {
        window.coverage_annual_family_oop_total.value = ""
        window.coverage_family_oop_remaining.value = ""
      }
    } else if(amountType == "Co Insurance" && levelCode == "IND") {
      if(row.hasClass("selected")) {
        const amt = row.find("[data-col='amount']").text()
        window.coverage_co_insurance.value = (new Number(amt.slice(1)) * 100) + "%"
      } else {
        window.coverage_co_insurance.value = ""
      }
    } else if(amountType == "Co Payment" && levelCode == "IND") {
      if(row.hasClass("selected")) {
        const amt = row.find("[data-col='amount']").text()
        window.coverage_co_payment.value = (new Number(amt.slice(1)) * 100) + "%"
      } else {
        window.coverage_co_payment.value = ""
      }
    }
  }

  findRowByAmountTypeAndLevelCode(amountType, levelCode) {
    const rows = $(this.element).find("tr")
    let selectedRows = []

    rows.each(function(ix) {
      const $this = $(this)
      if($this.find("td[data-col='amountType']").text() == amountType &&
         $this.find("td[data-col='levelCode']").text() == levelCode) {
        selectedRows = selectedRows.concat($this)
      }
    });

    return selectedRows
  }

  unselectBenefits(event) {
    console.log(event.target.outerHTML)
    const levelCode = event.target.dataset.levelCode
    const amountType = event.target.dataset.amountType
    const rows = this.findRowByAmountTypeAndLevelCode(amountType, levelCode)

    for(const row of rows) {
      const $row = $(row)
      $row.removeClass('selected')
      $row.find("input.boolean")[0].checked = false
    }
  }
}
