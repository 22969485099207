import { Controller } from "@hotwired/stimulus"
import { post, patch, put } from '@rails/request.js'
import('@app/signature/topaz_signature.js')
import('@app/signature/signature_controller.js')
import('@app/signature/signature_pad.js')

export default class extends Controller {

  static targets = [
    'refresh'
  ]

  connect() {
    this.initialize()
  }

  refreshTargetConnected() {
    this.initialize()
  }

  initialize() {
    $('#guarantor_relationship_dropdown').select2({ theme: 'default select2-container--modal w-100', placeholder: 'Choose Relationship', allowClear: true, minimumResultsForSearch: Infinity, dropdownParent: '#add-patient-signature-modal .modal-content'});
    $('body').tooltip({selector: '[rel="tooltip"]'});
  }

  clearAttachScriptErrors(event) {
    $(event.currentTarget).removeClass('error')
  }

  showAttachedScriptModal() {
    const paId = $('.page-header').data('id');
    const signatures_path = `/admin/patient_agreements/${paId}/scripts/new`;
    return $.get(signatures_path, {'patient_agreement[script_attached]': 1}, function(html) {
      $("#attached-script-form").replaceWith(html);
      return $("#attached-script-modal").modal('show');
    });
  }

  refreshPatientSignatureModalWithLanguage() {
    const paId = $('.page-header').data('id');
    return $.ajax({
      type: 'GET',
      url: `/admin/patient_agreements/${paId}/signatures/refresh_patient_signature_modal_with_language`,
      data: { language: 'en', version: '2.0' }
    });
  }

  resetProviderSelect() {
    $('#patient_agreement_modal_medical_professional_id').val('').trigger('change');
  }

  clearGuarantorErrors(event) {
    if ($(event.currentTarget).val().length > 0) {
      return $(".guarantor-name .error-text").addClass('d-none');
    }
  }

  validateGuarantorRelationship(event) {
    const relationship = $(event.currentTarget).val();
    const $guarantorNameInput = $("input[name='document_signature[guarantor_name]']")
    const name = $guarantorNameInput.val();
    const patient_name = $.trim($('.patient-name')[0].innerHTML);
    const self = '00';

    if (relationship === self) {
      $guarantorNameInput.val(patient_name);
      $guarantorNameInput.attr('disabled', true);
      return $(".patient-signature-form .error-text").addClass('d-none');
    } else if ((relationship !== self) && (name === patient_name)) {
      $guarantorNameInput.val('');
      $guarantorNameInput.removeAttr('disabled');
      $guarantorNameInput.focus();
      return $(".guarantor-relationship .error-text").addClass('d-none');
    } else {
      $guarantorNameInput.removeAttr('disabled');
      $guarantorNameInput.focus();
      return $(".guarantor-relationship .error-text").addClass('d-none');
    }
  }

  updateReceiptAcceptance(event) {
    const $target = $(event.currentTarget);
    const $container = $target.closest('.modal-sidebar');
    const selector = (() => { switch ($target.val()) {
      case '0': return 'receipt-acceptance-email';
      case '1': return 'receipt-acceptance-print';
      case '2': return 'receipt-acceptance-decline';
    } })();
    $container.find('.receipt-acceptance').addClass('d-none');
    return $container.find(`.${selector}`).removeClass('d-none');
  }

  updatePatientEmailFromPA(event) {
    let email = $("#patient_agreement_patient_email").val();
    if ($("#document_signature_patient_email").val()) {
      email = $("#document_signature_patient_email").val();
    }
    return $("input[name='document_signature[patient_email]']").val(email);
  }

  changeLanguage(event) {
    const paId = $('.page-header').data('id');
    const language = $("#add-patient-signature-modal .terms-form").find('input[name="document_signature[language]"]:checked').val();
    return $.ajax({
      type: 'GET',
      url: `/admin/patient_agreements/${paId}/signatures/refresh_patient_signature_modal_with_language`,
      data: { language, version: '2.0' }
    });
  }

  updateAttachedScriptLink(event, canAttach) {
    const $link = $('#attached-script-link');

    if ($link.length === 0) { return; }

    if (canAttach) {
      return $link.removeClass('disabled');
    } else {
      return $link.addClass('disabled');
    }
  }

  updateSetMissingProviderSubmit(event) {
    if ($(event.currentTarget).val() !== '') {
      return $('#set-missing-provider').prop('disabled', false);
    } else {
      return $('#set-missing-provider').prop('disabled', true);
    }
  }

  setMissingProvider() {
    const paId = $('.page-header').data('id');
    const providerId = $('#patient_agreement_modal_medical_professional_id').val();

    patch(`/admin/patient_agreements/${paId}`, {
      body: { section_name: 'medical_information', patient_agreement: { medical_professional_id: providerId }},
      responseKind: "turbo-stream"
    })
  }

  updatePopupAfter() {
    const button = $(event.relatedTarget);
    const popupAfter = button.data('popupAfter');
    return $('#patient-signature-blocked-modal [name=after_popup]').val(popupAfter);
  }

  showSignatureModal(signatureType, signatureText, data) {
    data = data || {};
    $(`#add-${signatureType}-signature-modal`).modal('hide');
    return window.openSignaturePad({
      lang:  $('#document_signature_language_spanish').attr('checked') ? 'es' : 'en',
      signatureText,
      async success(imageData) {
        if (signatureType === 'physician') {
          data.signer = 'doctor';
        } else {
          data.signer = 'patient';
        }
        data.patient_agreement_id = $('.page-header').data('id');
        data.image_base64 = imageData;
        BigSpinner.enableOnce()
        BigSpinner.add()
        await post('/admin/signatures', {
          body: { document_signature: data },
          responseKind: "turbo-stream"
        }).then(() => {
          BigSpinner.finished()
          if(typeof success == 'function'){success()}
        }).catch(() => {
          BigSpinner.finished()
          if(typeof error == 'function'){error()}
        })
      }
    });
  }

  validateAcceptanceTypeAndEmailAndSubmit() {
    const patientEmail = $("#email-receipt-modal").find(".email-name input:visible").val();
    if (this.acceptanceTypeAndEmailValid('0', patientEmail)) {
      $("#send-email-form .email-name .error-text").addClass('d-none');
      return $("#email-receipt-modal").modal('hide');
    } else {
      event.preventDefault();
      $("#send-email-form .email-name .error-text").removeClass('d-none');
      return false;
    }
  }

  showPhysicianSignatureModal() {
    event.preventDefault();
    return this.showSignatureModal('physician', $('.page-header').data('provider-name'));
  }

  initSignatures() {
    const paId = $('.page-header').data('id');
    const signatures_path = `/admin/patient_agreements/${paId}/signatures`;
  }

  submitForm() {
    event.preventDefault();
    const $relationshipSelect = $("#add-patient-signature-modal .terms-form").find('#guarantor_relationship_dropdown');
    const relationship = $relationshipSelect.val();
    const name = $("#add-patient-signature-modal .terms-form").find("input[name='document_signature[guarantor_name]']").val();
    const acceptanceType = $("#add-patient-signature-modal .terms-form").find('input[name="document_signature[acceptance_type]"]:checked').val();
    const patientEmail = $("#add-patient-signature-modal .terms-form").find("input[name='document_signature[patient_email]']").val();
    const primaryZip = $("#add-patient-signature-modal .terms-form").find('#document_signature_patient_agreement_attributes_competitive_bidding_primary_zip').val();

    if (this.acceptanceTypeAndEmailValid(acceptanceType, patientEmail) && this.relationshipAndNameValid(relationship, name) && this.primaryZipValid(primaryZip)) {
      let relationshipText = $relationshipSelect.find("option:selected")[0].text || "";
      if (relationshipText) {
        relationshipText = ' (' + relationshipText + ')';
      }
      $(".patient-email-row .data-value a").text(patientEmail);
      return this.showSignatureModal('patient', name + relationshipText, {
        guarantor_relationship: relationship,
        guarantor_name: name,
        acceptance_type: acceptanceType,
        patient_email: patientEmail,
        patient_agreement_attributes: {
          competitive_bidding_primary_zip: primaryZip
        }
      });
    } else {
      this.verifyForm('terms');
      return false;
    }
  }

  acceptanceTypeAndEmailValid(acceptanceType, patientEmail) {
    const email = '0';
    if (acceptanceType) {
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !((acceptanceType === email) && ((patientEmail.length === 0) || !patientEmail.match(emailRegex)));
    } else {
      return false;
    }
  }

  relationshipAndNameValid(relationship, name) {
    const self = '00';
    return (relationship && name) || (relationship === self);
  }

  primaryZipFieldIsShown() {
    return $("#add-patient-signature-modal .terms-form").
      find('#document_signature_patient_agreement_attributes_competitive_bidding_primary_zip').
      is(":visible")
  }

  primaryZipValid(primaryZip) {
    const zipRegex = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
    return (!!primaryZip && primaryZip.match(zipRegex))|| !this.primaryZipFieldIsShown();
  }

  verifyForm(form){
    const relationship = $("#add-patient-signature-modal .terms-form").find('#guarantor_relationship_dropdown').val();
    const name = $("#add-patient-signature-modal .terms-form").find("input[name='document_signature[guarantor_name]']").val();
    const acceptanceType = $("#add-patient-signature-modal .terms-form").find('input[name="document_signature[acceptance_type]"]:checked').val();
    const patientEmail = $('#add-patient-signature-modal .terms-form').find("input[name='document_signature[patient_email]']").val();
    const primaryZip = $("#add-patient-signature-modal .terms-form").find('#document_signature_patient_agreement_attributes_competitive_bidding_primary_zip').val();
    const self = '00';
    const email = '0';

    if (relationship === '') {
      $(`.${form}-form .guarantor-relationship .error-text`).removeClass('d-none');
    } else {
      $(`.${form}-form .guarantor-relationship .error-text`).addClass('d-none');
    }

    if (name === '') {
      $(`.${form}-form .guarantor-name .error-text`).removeClass('d-none');
    } else {
      $(`.${form}-form .guarantor-name .error-text`).addClass('d-none');
    }

    if ((acceptanceType == null) || (acceptanceType === '')) {
      $(`.${form}-form .acceptance-type .error-text`).removeClass('d-none');
    } else {
      $(`.${form}-form .acceptance-type .error-text`).addClass('d-none');
    }

    if (!this.acceptanceTypeAndEmailValid(acceptanceType, patientEmail)) {
      $(`.${form}-form .email-name .error-text`).removeClass('d-none');
    } else {
      $(`.${form}-form .email-name .error-text`).addClass('d-none');
    }

    if (this.primaryZipFieldIsShown()) {
      if (!this.primaryZipValid(primaryZip)) {
        return $(`.${form}-form .primary-zip .error-text`).removeClass('d-none');
      } else {
        return $(`.${form}-form .primary-zip .error-text`).addClass('d-none');
      }
    }
  }

  docusignYes(event) {
    $("#docusign-confirmation-modal").modal("hide")
    BigSpinner.add()

    const path = event.target.dataset.path
    const method = event.target.dataset.method
    if(method == 'post') {
      post(path, { responseKind: "turbo-stream" }).then(() => {
        BigSpinner.finished();
        $("#turbo-modal").modal("show")
      })
    } else {
      put(path, { responseKind: "turbo-stream" }).then(() => {
        BigSpinner.finished();
        $("#turbo-modal").modal("show")
      })
    }
  }
}
